




























































































import { Component, Watch, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import moment from 'moment'
import algoliasearch from 'algoliasearch'
import Common from '../../common/Common'
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'
import VueUploadComponent from 'vue-upload-component'
import markdownIt from "markdown-it"
import Cropper from 'cropperjs'
import PartnerComponent from "@/components/chat/PartnerComponent.vue"
require("moment-duration-format")

@Component({
  components: {
    ClipLoader,
    VueUploadComponent,
    PartnerComponent
  },
})
export default class Profile extends Vue {
  isLoading = true
  md = new markdownIt({html: false, breaks: true})
  mydata = true
  userInfo: any = {}
  questions: any[] = []
  classes: any[] = []
  
  @Watch('$route')
  changeUid() {
    this.search()
  }

  mounted() {
    this.search()
  }

  search() {
    this.isLoading = true
    this.questions = []
    let uid: any
    if (this.$route.query.uid && this.$route.query.uid != Common.getUserId()) {
      uid = this.$route.query.uid
      this.mydata = false
    } else {
      sessionStorage.removeItem('user')
      this.mydata = true
      uid = Common.getUserId()
    }
    let promises: any = []
    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').where('uid', '==', uid).where('type', '==', 'question').orderBy('createTime', 'desc').limit(5).get().then((data) => {
        data.docs.forEach((d) => {
          let qs = d.data()!
          this.questions.push({title: qs.title, date: qs.createTime ? moment(qs.createTime.toDate()).format('YYYY/MM/DD') : '', id: d.id})
        })
        resolve()
      })
    }))

    promises.push(new Promise((resolve) => {
      firebase.firestore().collection('onayamis').where('uid', '==', uid).where('type', '==', 'class').where('finish', '==', false).orderBy('createTime', 'desc').limit(5).get().then((data) => {
        data.docs.forEach((d) => {
          let qs = d.data()!
          this.classes.push({title: qs.title, date: qs.createTime ? moment(qs.createTime.toDate()).format('YYYY/MM/DD') : '', id: d.id, category: qs.category})
        })
        resolve()
      })
    }))

    promises.push((<any>this.$refs.ptcom).loadWithUid(uid))

    Promise.all(promises)
      .finally(() => {
        this.isLoading = false
        if (!this.userInfo.name) {
          this.$router.push(`/user-setting`)
        }
        if (this.$route.hash == '#rate') {
          setTimeout(() => {
            (<any>document.getElementById('rate')).scrollIntoView()
          }, 500)
        }
      })
  }
}
